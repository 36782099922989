

















































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import CardWithTitle from '@/components/Card/CardWithTitle.vue'

@Component({ 
  components:{ 
    CardWithTitle
  }
})
export default class TermsOfUse extends Vue {

}
